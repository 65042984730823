@import "~antd/dist/antd.css";
@import "antd-mobile/dist/antd-mobile.css";

#event_login {
  width: 80%;
  margin-top: 150px;
}

.svs {

  font-size: 25px;
  margin-bottom: 25px;
}

.login-form-button {
  margin-right: 10px;
}

.logo {
  width: 130px;
  height: auto;
  margin-top: 100px;
  margin-bottom: 30px;
  font-size: 30px;
}

#tab-bar.demo {
  display: flex;
  flex-direction: column;
}

#login-wrapper .login-form {
  max-width: 300px;
  margin: 0 auto;
}
#login-wrapper .login-form-forgot {
  float: right;
}
#login-wrapper .ant-col-rtl .login-form-forgot {
  float: left;
}
#login-wrapper .login-form-button {
  width: 100%;
}

#form-wrapper .login-form {
  max-width: 500px;
  margin: 0 auto;
}
#form-wrapper .login-form-forgot {
  float: right;
}
#form-wrapper .ant-col-rtl .login-form-forgot {
  float: left;
}
#form-wrapper .login-form-button {
  width: 100%;
}

#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}
#tab-bar .demoName {
  height: 40px;
}
#tab-bar .demo-preview-item .am-tab-bar {
  background-color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #3c66b9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error {
  color: red;
  font-weight: bold;
}